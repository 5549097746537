<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import HomeModel from '@/modules/home/home-model';
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
import i18n from '@/shared/plugins/vue-i18n';

const { fields } = HomeModel;
const store = {
  state: {
    secondSelection: 'progress_val',
    optionsDep: [],
    optionsGroup: [],
    optionsStudent: [],
    optionsYear: [{
      value: 2022,
      text: 2022,
    }, {
      value: 2023,
      text: 2023,
    }, {
      value: 2024,
      text: 2024,
    }],
    department: null,
    group: null,
    student: null,
    statistics: null,
    statisticsProgress: null,
    statisticsKnowledge: null,
    optionsCategories: [],
    optionsTags: [],
    role: window.localStorage.getItem('role'),
  },
};
export default {
  name: 'HomePage',
  components: {
    VueApexCharts,
  },
  data() {
    return {
      fields,
      store,
      model: {},
      chartOptions: {
        colors: ['#B2FFDE', '#CDD1FF', '#F1CCFF', '#FFD8D8', '#FFE68F', '#B2FFDE'],
        chart: {
          height: 350,
          type: 'area',
          id: 'vuechart-example',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          categories: [
            '2022-01-01', '2022-02-01',
            '2022-03-01', '2022-04-01',
            '2022-05-01', '2022-06-01',
            '2022-07-01', '2022-08-01',
            '2022-09-01', '2022-10-01',
            '2022-11-01', '2022-12-01',
          ],
        },
        tooltip: {
          x: {
            format: 'MMMM yy',
            locale: 'ro',
            lang: 'ro',
          },
        },
      },
      series: [{
        name: 'DEL completed',
        data: [70, 80, 75, 70, 60, 65, 70, 91, 69, 60, 70, 80],
      }, {
        name: 'Accuracy',
        data: [30, 40, 35, 50, 49, 60, 65, 80, 49, 50, 55, 45],
      }, {
        name: 'Memorization',
        data: [10, 20, 15, 20, 29, 30, 40, 51, 29, 40, 30, 20],
      },
      ],
    };
  },
  methods: {
    ListBranches(parentId, parentType) {
      switch (parentType) {
        case 'department':
          this.model.departament = parentId;
          store.state.optionsGroup = [];
          store.state.optionsStudent = [];
          this.ListGraphInfo();
          break;
        default:
          break;
      }

      const listDepPath = '/WebListBranches.php';
      const data = new FormData();
      data.append('parent_id', parentId);
      data.append('parent_type', parentType);
      data.append('levels', 2);
      fetch(server + listDepPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.branches && json.branches.length) {
          json.branches.forEach((branch) => {
            if (branch.type === 'department') {
              store.state.optionsDep.push({
                value: branch.id,
                text: branch.name,
                students_list: branch.students_list,
              });
            }
            if (branch.type === 'group') {
              store.state.optionsGroup.push({
                value: branch.id,
                text: branch.name,
                students_list: branch.students_list,
              });
            }
          });
          if (store.state.optionsDep.length === 1) {
            store.state.optionsDep.forEach((dep, i) => {
              if (i === 0 && this.model.departament !== dep.value) {
                this.model.departament = dep.value;
                this.ListBranches(dep.value, 'department');
              }
            });
          }
          if (store.state.optionsGroup.length === 1) {
            store.state.optionsGroup.forEach((group, i) => {
              if (i === 0 && this.model.group !== group.value) {
                this.model.group = group.value;
                this.ListGraphInfo();
                this.ListStudents(group.value);
              }
            });
          }
        }
      });
    },
    ListCategories() {
      const listCatPath = '/WebListTagCategories.php';
      fetch(server + listCatPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.categories && json.categories.length) {
            json.categories.forEach((item) => {
              store.state.optionsCategories.push({
                value: parseInt(item.id, 10),
                text: item.name,
              });
            });
          }
          this.searchTags();
        });
    },
    searchTags() {
      const listTagsPath = '/WebListTags.php';
      const data = new FormData();
      if (this.model.category !== 0) {
        data.append('category_id', this.model.category);
      }
      fetch(server + listTagsPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            }
          }
          if (json.tags && json.tags.length) {
            json.tags.forEach((item) => {
              this.store.state.optionsTags.push({
                value: parseInt(item.id, 10),
                text: item.tag,
              });
            });
          } else {
            store.state.optionsTags = [];
          }
        });
    },
    ListStatistics() {
      const listSubPath = '/WebGlobalStatistics.php';
      fetch(server + listSubPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json) {
            store.state.statistics = json;
          }
        });
    },
    ListStudents(e) {
      this.ListGraphInfo();
      store.state.optionsStudent = [];
      store.state.optionsGroup.forEach((group) => {
        if (e === group.value && group.students_list) {
          group.students_list.forEach((student) => {
            store.state.optionsStudent.push({
              value: student.id,
              text: student.name,
            });
          });
          if (store.state.optionsStudent.length === 1) {
            store.state.optionsStudent.forEach((student, i) => {
              if (i === 0) {
                this.ListGraphInfo();
              }
            });
          }
        }
      });
    },
    ListGraphInfo() {
      const listSubPath = '/WebYearStatistics.php';
      const data = new FormData();
      data.append('year', this.model.year);
      if (this.model.student) {
        data.append('student_id', this.model.student);
        data.append('type', 'student');
      } else if (this.model.group) {
        data.append('branch_id', this.model.group);
        data.append('type', 'group');
      } else {
        data.append('branch_id', this.model.departament);
        data.append('type', 'department');
      }

      if (this.model.tag) {
        data.append('tag', this.model.tag);
      }
      if (this.model.category) {
        data.append('tag', this.model.category);
      }

      fetch(server + listSubPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json) {
            store.state.statisticsProgress = Object.entries(json.statistics.progress).map(
              (arr) => ({
                name: this.getName(arr[0]),
                data: Object.values(arr[1]),
              }),
            );
            store.state.statisticsKnowledge = Object.entries(json.statistics.knowledge).map(
              (arr) => ({
                name: this.getName(arr[0]),
                data: Object.values(arr[1]),
              }),
            );
          }
        });
    },
    getName(key) {
      switch (key) {
        case 'level1':
          return i18n.t('DEPARTAMENTS.ELEMENTARY');
        case 'level2':
          return i18n.t('DEPARTAMENTS.MEDIUM');
        case 'level3':
          return i18n.t('DEPARTAMENTS.EXPERT');
        case 'level4':
          return i18n.t('DEPARTAMENTS.APPLICATION');
        case 'level5':
          return i18n.t('DEPARTAMENTS.LIFEHACK');
        case 'accuracy':
          return i18n.t('DEPARTAMENTS.ACCURACY');
        case 'memorization':
          return i18n.t('DEPARTAMENTS.MEMORIZATION');
        default:
          return i18n.t('DEPARTAMENTS.ELEMENTARY');
      }
    },
  },
  beforeMount() {
    moment.locale('ro');
    store.state.optionsDep = [];
    store.state.optionsGroup = [];
    store.state.optionsStudent = [];
    this.model.year = 2024;
    this.ListStatistics();
    this.ListCategories();

    this.ListBranches(0, 'faculty');
    store.state.optionsSubject = [];
    store.state.optionsCourse = [];
  },
};
</script>
<template lang="html">
  <div :class="'home-container ' + (store.state.role.includes('mentor') ? 'mentor' : '')"
       v-if="store.state.statistics">
    <div class="home-card-top" v-on:click="$router.push('/management/faculty/0')"
  v-if="store.state.role.includes('admin')">
      <div class="icon-cont" style="background-color: #E8F4FA">
        <unicon name="folder-open" fill="#1694D0"/>
      </div>
      <div>
        <div>
          <div class="basic-3" style="margin-bottom: 4px">
            {{ $t("GENERAL.DEPARTAMENT") }}
          </div>
          <div class="title-1-bold-4 basic-1">
            {{ store.state.statistics.department_amount }}
          </div>
        </div>
      </div>
    </div>
    <div class="home-card-top" v-on:click="$router.push(store.state.role.includes('admin')  ?
     '/management/department/0'
         : '/management/group/0')">
      <div class="icon-cont" style="background-color: #DBFFEF">
        <unicon name="users-alt" fill="#00CA72"/>
      </div>
      <div>
        <div>
          <div class="basic-3" style="margin-bottom: 4px">
            {{ $t("DEPARTAMENTS.GROUPS") }}
          </div>
          <div class="title-1-bold-4 basic-1">
            {{ store.state.statistics.group_amount }}
          </div>
        </div>
      </div>
    </div>
    <div class="home-card-top" v-on:click="$router.push(store.state.role.includes('admin')  ?
     '/management/department/0'
         : '/management/group/0')">
      <div class="icon-cont" style="background-color: #FFF6D6">
        <unicon name="user-circle" fill="#FDAB3D"/>
      </div>
      <div>
        <div>
          <div class="basic-3" style="margin-bottom: 4px">
            {{ $t("DEPARTAMENTS.STUDENTS") }}
          </div>
          <div class="title-1-bold-4 basic-1">
            {{ store.state.statistics.students_amount }}
          </div>
        </div>
      </div>
    </div>
    <div class="home-card-top" v-on:click="$router.push('/categories')">
      <div class="icon-cont" style="background-color: #FFE9E9">
        <unicon name="book-open" fill="#E2445C"/>
      </div>
      <div>
        <div>
          <div class="basic-3" style="margin-bottom: 4px">
            {{ $t("TAGS.CARDS") }}
          </div>
          <div class="title-1-bold-4 basic-1">
            {{ store.state.statistics.questions_amount }}
          </div>
        </div>
      </div>
    </div>
    <div class="home-card-top me-0" v-on:click="$router.push('/categories')">
      <div class="icon-cont" style="background-color: #E8F4FA">
        <unicon name="play-circle" fill="#1694D0"/>
      </div>
      <div>
        <div>
          <div class="basic-3" style="margin-bottom: 4px">
            {{ $t("TAGS.TAGS") }}
          </div>
          <div class="title-1-bold-4 basic-1">
            {{ store.state.statistics.tags_amount }}
          </div>
        </div>
      </div>
    </div>
    <div class="home-card-left">
      <div>
        <div class="basic-1 headline-bold-16" style="margin-bottom: 12px">
          {{ $t("DEPARTAMENTS.STUDENTS") }}
        </div>
        <div class="custom-input" style="margin-bottom: 8px"
             v-if="store.state.role.includes('admin')">
          <label class="caption-2-reg-12 basic-3">
            {{ $t("GENERAL.DEPARTAMENT") }}</label>
          <b-form-select
            :id="fields.departament.name"
            v-model="model[fields.departament.text]"
            :placeholder="fields.departament.label"
            :options="store.state.optionsDep"
            required
            v-on:change="ListBranches($event, 'department')"
          >
          </b-form-select>
        </div>
        <div class="custom-input" style="margin-bottom: 8px">
          <label class="caption-2-reg-12 basic-3">
            {{ $t("DEPARTAMENTS.GROUPS") }}</label>
            <b-form-select
              :id="fields.group.name"
              v-model="model[fields.group.name]"
              :placeholder="fields.group.label"
              :options="store.state.optionsGroup"
              required
              v-on:change="ListStudents($event)"

            >
          </b-form-select>
        </div>
        <div class="custom-input" style="margin-bottom: 24px">
          <label class="caption-2-reg-12 basic-3">
            {{ $t("DEPARTAMENTS.STUDENTS") }}</label>
          <b-form-select
            :id="fields.student.name"
            v-model="model[fields.student.name]"
            :placeholder="fields.student.label"
            :options="store.state.optionsStudent"
            required
            v-on:change="ListGraphInfo"
          >
          </b-form-select>
        </div>

        <div class="basic-1 headline-bold-16" style="margin-bottom: 12px">
          {{ $t("TAGS.TAGS") }}
        </div>
        <div class="custom-input" style="margin-bottom: 8px">
          <label class="caption-2-reg-12 basic-3">
            {{ $t("TAGS.CATEGORY") }}</label>
          <b-form-select
            :id="fields.category.name"
            v-model="model[fields.category.name]"
            :placeholder="fields.category.label"
            :options="store.state.optionsCategories"
            required
            v-on:change="searchTags()"
          >
          </b-form-select>
        </div>

        <div class="custom-input" style="margin-bottom: 8px">
          <label class="caption-2-reg-12 basic-3">
            {{ $t("TAGS.TAG") }}</label>
          <b-form-select
            :id="fields.tag.name"
            v-model="model[fields.tag.name]"
            :placeholder="fields.tag.label"
            :options="store.state.optionsTags"
            required
            v-on:change="ListGraphInfo()"
          >
          </b-form-select>
        </div>
      </div>
    </div>
    <div class="home-card-right position-relative">
      <div class="basic-1 title-0-medium-28" style="margin-bottom: 16px">
        {{ $t("GENERAL.PERFORMANCE") }}

        <div class="custom-input" style="position: absolute;
        top: 24px;right: 24px;width: fit-content">
          <b-form-select
            :id="fields.year.name"
            v-model="model[fields.year.name]"
            :placeholder="fields.year.label"
            :options="store.state.optionsYear"
            required
            v-on:change="ListGraphInfo"
          >
          </b-form-select>

        </div>
      </div>

      <div class="second-select">
        <div :class="'subhead-reg-14 basic-1 selector progress_val '
            + (store.state.secondSelection === 'progress_val' ? 'active' : '')"
             v-on:click="store.state.secondSelection = 'progress_val'">

          <unicon height="20" width="20" name="chart-line" fill="#1694D0"></unicon>
          {{ $t("DEPARTAMENTS.PROGRESS") }}
        </div>
        <div :class="'subhead-reg-14 basic-1 selector knowledge '
            + (store.state.secondSelection === 'knowledge' ? 'active' : '')"
             v-on:click="store.state.secondSelection = 'knowledge'">

          <unicon height="20" width="20" name="chart" fill="#00CA72"></unicon>
          {{ $t("DEPARTAMENTS.LEARNING_STAGES") }}

        </div>
      </div>
      <div v-if="store.state.statisticsProgress &&
      store.state.secondSelection === 'progress_val'">
        <vue-apex-charts :options="chartOptions" :series="store.state.statisticsProgress">
        </vue-apex-charts>
      </div>
      <div v-if="store.state.statisticsKnowledge &&
      store.state.secondSelection === 'knowledge'">
        <vue-apex-charts :options="chartOptions" :series="store.state.statisticsKnowledge">
        </vue-apex-charts>
      </div>
    </div>
  </div>
</template>

<style>
.home-container {
  display: flex;
  flex-wrap: wrap;
}
.home-card-top {
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 16px;
  flex: 0 0 auto;
  width: calc(20% - 19.2px);
  margin-right: 24px;
  display: inline-flex;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 24px;
}
.home-container.mentor .home-card-top{
  width: calc(25% - 18px);
}
.home-card-top .icon-cont {
  padding: 12px;
  width: 48px;
  height: 48px;
  background: #E8F4FA;
  border-radius: 8px;
  margin-right: 16px;
  cursor: pointer;
}
.home-card-left{
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 16px;
  flex: 0 0 auto;
  width: 24%;
  margin-right: 16px;
  margin-bottom: 24px;
}
.home-card-right {
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  flex: 0 0 auto;
  width: calc(76% - 16px);
  margin-bottom: 24px;
  padding: 24px;
}
.home-card-right .second-select .selector {
  padding: 10px 12px;
  border-radius: 8px;
  width: 50%;
  cursor: pointer;
}
.second-select .unicon {
  height: 20px;
}
.second-select .unicon svg{
  margin-top: -4px;
}
.home-card-right .second-select {
  background: #F5F6F8;
  border-radius: 8px;
  display: inline-flex;
  width: 100%;
  margin-bottom: 16px;
}
.selector.progress_val.active, .selector.progress_val:hover{
  background-color: #E8F4FA;
}
.selector.knowledge.active,.selector.knowledge:hover {
  background: #DBFFEF;
}
.home-card-right .custom-input select {
  height: unset;
}
.third-select {
  display: inline-flex;
  width: 100%;
  margin-bottom: 16px;
}
.third-select .selector {
  margin-right: 24px;
  cursor: pointer;
}
.third-select .selector.active {
  border-bottom: 1px solid #1694D0;
  color: #1694D0;
}
.home-card-bottom{
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 16px;
  flex: 0 0 auto;
  width: calc(33.333% - 16px);
  margin-bottom: 24px;
  margin-right: 24px;
  position: relative;
  cursor: pointer;
}
.home-card-bottom .img-more {
  position: absolute;
  padding: 12px;
  right: 8px;
  top: 8px;
}

.home-card-bottom .img-more:hover {
  background: #E8F4FA;
  border-radius: 8px;
}
.home-card-bottom .img-more:active {
  background: #CDD1FF;
  border-radius: 8px;
}
.home-card-bottom .img-more:hover svg {
  fill: #1694D0;
}
.home-card-bottom .img-more:active svg {
 fill: #1F2FD7;
}
.home-card-bottom .image-cont img{
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-right: 12px;
}
.home-card-bottom .contains {
  display: flex;
  align-items: center;
}
.home-card-bottom .ellipse {
  width: 4px;
  height: 4px;
  background: #1694D0;
  border-radius: 50%;
  margin-right: 6px;
}
</style>
