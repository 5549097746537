import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';

const label = (name) => i18n.t(`TAGS.FIELDS.${name.toUpperCase()}`);

const fields = {
  departament: new StringField('departament', label('departament'), {
    required: true,
  }),
  group: new StringField('group', label('group'), {
    required: true,
  }),
  student: new StringField('student', label('student'), {
    required: true,
  }),
  year: new StringField('year', label('year'), {
    required: true,
  }),
  category: new StringField('category', label('category'), {
    required: true,
  }),
  tag: new StringField('tag', label('tag'), {
    required: true,
  }),
};

export default class HomeModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
